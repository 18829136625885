export const CardSectionTitle = ({ index, title }) => {
  return (
    <div>
      <h3 className='rounded-5 bg-skyblue-bg color-blue-text d-inline-flex justify-content-center align-items-center  p-3 px-4 fw-bold my-4 fz-14'>
        <span className='position-relative z-100 me-2 color-skyblue-bg bg-blue-text rounded-5 wh-20 text-center d-flex flex-row justify-content-center align-items-center text-white'>
          {index}
        </span>
        {title}
      </h3>
    </div>
  );
};
