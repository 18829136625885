import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';

import { CardHeader, SpinnerLoading } from 'components/common';
import StudentProgressEmpty from 'components/common/dashboard/StudentProgressEmpty';
import AdminSelectBox from 'components/subject/AdminSelectBox';
import JandiList from 'components/subject/JandiList';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { JANDI_CHANGER } from 'util/jandi/jandiUtil';

export function AdminProgress({ cardTitle }) {
  const loginState = useRecoilValue(loginUser);

  const [courseId, setCourseId] = useState({
    subjectId: null,
    lectureId: null,
  });
  const [filterData, setFilterData] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const [subOptionData, setSubOptionData] = useState([]);

  // const { teacherStudentProgressData } = useGetTeacherQuery();

  const {
    // 관리자 - 전체 학과 리스트 조회
    adminSelectBoxQueryData,
    adminSelectBoxQueryIsLoading,
    adminSelectBoxQueryIsError,

    // 관리자 - 해당 학과의 과목 리스트
    adminSubSelectBoxQueryData,
    adminSubSelectBoxQueryIsLoading,
    adminSubSelectBoxQueryIsError,
  } = useGetRequiredApi(courseId?.lectureId);

  // 교수자 - [홈] 과목 학생별 진행정보
  const { data: teacherStudentProcessRate, isLoading: teacherStudentProcessRateIsLoading } = useQuery({
    queryKey: ['teacherStudentProcessRate', loginState, courseId],
    queryFn: () => getDataQuery(`/teach/home/course/${courseId?.subjectId}/students/progress-info`),
    enabled: !!courseId?.subjectId && !!courseId?.lectureId,
    select: (data) => JANDI_CHANGER(JSON.parse(data?.data)),
  });

  // 학과 이벤트 핸들러
  const handleSelectIdBox = (e) => {
    setCourseId({ ...courseId, lectureId: e.value });
  };

  // 과목 이벤트 핸들러
  const handleSubjectSelectBox = (e) => {
    setCourseId({ ...courseId, subjectId: e.value });
  };

  // 학과 셀렉트 박스 데이터
  useEffect(() => {
    return setOptionData(
      adminSelectBoxQueryData?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [adminSelectBoxQueryData]);

  // 과목 셀렉트 박스 데이터
  useEffect(() => {
    return setSubOptionData(
      adminSubSelectBoxQueryData?.map((item) => ({
        value: item.account_course_dto.course_id,
        label: item.account_course_dto.course_name,
      }))
    );
  }, [adminSubSelectBoxQueryData]);

  useEffect(() => {
    if (teacherStudentProcessRate) setFilterData(teacherStudentProcessRate);
  }, [teacherStudentProcessRate]);

  useEffect(() => {
    return () => {
      setCourseId(null);
    };
  }, []);

  return (
    <div className='col-12'>
      <div className='card recent-sales overflow-auto p-20'>
        <div style={{ minHeight: '400px' }}>
          <CardHeader title='학습 학생 진도율'>{cardTitle}</CardHeader>

          <div className='d-flex align-items-center'>
            <AdminSelectBox
              lectureIsLoading={adminSelectBoxQueryIsLoading}
              lectureOption={optionData}
              lectureOnChange={handleSelectIdBox}
              lecturePlaceholder={'학과 선택'}
              subjectIsLoading={adminSubSelectBoxQueryIsLoading}
              subjectOption={subOptionData}
              subjectOnChange={handleSubjectSelectBox}
              subjectPlaceholder={'과목 선택'}
              adminSelectState={courseId?.lectureId}
              adminSubSelectBoxQueryIsLoading={adminSubSelectBoxQueryIsLoading}
            />
          </div>
          <Table>
            <tbody>
              {/* 받아온 데이터 뿌려주기 */}
              {teacherStudentProcessRateIsLoading ? (
                <tr>
                  <td className='position-relative py-5'>
                    <SpinnerLoading />
                  </td>
                </tr>
              ) : teacherStudentProcessRate && teacherStudentProcessRate?.length > 0 ? (
                teacherStudentProcessRate?.map((item) => (
                  <>
                    <tr key={item.student_id} className='border-bottom mb-2'>
                      <td className='py-20 text-start --Palette-DarkGray-80 fz-18 fw-bold' scope='row'>
                        {item.name}
                      </td>
                      <td className='py-20 text-end --Palette-Gray-70 fz-12 align-middle'>주차완료</td>
                      <td className='py-20 w-50 pe-3'>
                        {/* <ProgressBar
                          striped
                          variant='chaInit'
                          now={decimalPointRemovalFn(+item.course_progress_rate)}
                        /> */}
                        <ul className='d-flex justify-content-between'>
                          {item?.progressResDtoList?.map((el, index) => (
                            <JandiList type={el.state}>{index + 1}</JandiList>
                          ))}
                        </ul>
                      </td>
                    </tr>
                    {/* <tr key={item.student_id} className='border-bottom mb-2'>
                    <td className='py-20 text-start --Palette-DarkGray-80 fz-18 fw-bold' scope='row'>
                      {item.student_name}
                    </td>
                    <td className='py-20 text-end --Palette-Gray-70 fz-16'>
                      진도율 {decimalPointRemovalFn(item.course_progress_rate)}%
                    </td>
                    <td className='py-20 w-50 pe-3'>
                      <ProgressBar striped variant='chaInit' now={decimalPointRemovalFn(+item.course_progress_rate)} />
                    </td>
                  </tr> */}
                  </>
                ))
              ) : (
                <>
                  <StudentProgressEmpty />
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
