import { useEffect, useId, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { useReportQuery } from './useReportQuery';

export const useReport = () => {
  const reportState = useRecoilValue(studentReportState);
  const user = useRecoilValue(loginUser);
  const [semesterOption, setSemesterOption] = useState([]);
  const [departmentOption, setDepartmentOption] = useState([]);
  const [adminDepartmentOption, setAdminDepartmentOption] = useState([]);
  const searchInputRef = useRef(null);

  const semesterId = useId();
  const departmentId = useId();
  const inputId = useId();

  const {
    학기_선택박스_정보,
    학기_선택박스_로딩,
    교수학과_정보,
    교수학과_로딩,
    관리자_전체학과선택_정보,
    관리자_전체학과선택_로딩,
  } = useReportQuery();

  // 학기 선택
  useEffect(() => {
    return setSemesterOption(
      학기_선택박스_정보?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [학기_선택박스_정보]);
  // useEffect(() => {
  //   return setSemesterOption(
  //     [
  //       { course_id: 1, course_name: '2020' },
  //       { course_id: 2, course_name: '2021' },
  //     ].map((item) => ({
  //       value: item.course_id,
  //       label: item.course_name,
  //     }))
  //   );
  // }, [학생_학과_리포트_선택박스_정보,]);

  // 학과 선택

  useEffect(() => {
    // 선생의 교수학과_정보 값은 문자열 셀렉트박스가 불필요 !
    if (교수학과_정보) {
      return setDepartmentOption([
        { value: 교수학과_정보?.data?.sub_division_id, label: 교수학과_정보?.data?.sub_division_name },
      ]);
    }
  }, [교수학과_정보]);

  useEffect(() => {
    if (관리자_전체학과선택_정보?.data) {
      return setAdminDepartmentOption(
        관리자_전체학과선택_정보?.data.map((item) => ({
          value: item.sub_division_id,
          label: item.sub_division_name,
        }))
      );
    }
  }, [관리자_전체학과선택_정보]);

  const semesterSelector = (visible = true, onChange, defaultValue) => (
    <>
      {visible && (
        <label htmlFor={`semester-${semesterId}`} className='white-nowrap mx-3 d-none d-sm-block'>
          학기
        </label>
      )}
      <AsyncSelect
        isSearchable
        onInputChange={false}
        id={`semester-${semesterId}`}
        className='w-100 h-35'
        defaultInputValue='학기 선택'
        placeholder='학기 선택'
        // isLoading={isLoading}
        cacheOptions
        defaultOptions={semesterOption}
        onChange={onChange}
        // loadOptions={semesterOption}
        value={defaultValue}
      />
    </>
  );

  const departmentSelector = (visible = true, onChange) => (
    <>
      {visible && (
        <label htmlFor={`department-${departmentId}`} className='white-nowrap mx-3 d-none d-sm-block'>
          학과
        </label>
      )}
      <AsyncSelect
        isSearchable
        id={`department-${departmentId}`}
        className='w-100 h-35'
        defaultInputValue='학과 선택'
        placeholder='학과 선택'
        isLoading={학기_선택박스_로딩 && !reportState}
        isDisabled={!reportState}
        cacheOptions
        defaultOptions={departmentOption}
        onChange={onChange}
        // loadOptions={departmentOption}
      />
    </>
  );

  const adminDepartmentSelector = (visible = true, onChange) => (
    <>
      {visible && (
        <label htmlFor={`department-${departmentId}`} className='white-nowrap mx-3 d-none d-sm-block'>
          학과
        </label>
      )}
      <AsyncSelect
        isSearchable
        id={`admin-department-${departmentId}`}
        className='w-100 h-35'
        defaultInputValue='학과 선택'
        placeholder='학과 선택'
        isLoading={학기_선택박스_로딩 && !reportState}
        isDisabled={!reportState}
        cacheOptions
        defaultOptions={adminDepartmentOption}
        onChange={onChange}
        // loadOptions={adminDepartmentOption}
      />
    </>
  );

  const reportSearchForm = (onSubmit, disabled = true) => {
    return (
      <>
        <form action='#' className='w-full' onSubmit={onSubmit}>
          <input
            ref={searchInputRef}
            disabled={disabled}
            className='border py-2 rounded w-full h-35'
            type='text'
            name={`searchInput${inputId}`}
            id={`searchInput${inputId}`}
            placeholder='학번/이름 검색'
          />
        </form>
      </>
    );
  };

  return {
    semesterOption,
    departmentOption,
    semesterSelector,
    departmentSelector,
    adminDepartmentSelector,
    reportSearchForm,
    searchInputRef,
  };
};
