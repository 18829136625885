import { useQuery } from '@tanstack/react-query';
import { postDataBodyQuery } from 'api/getDataBodyQuery';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { IsVideoSuccess } from 'components/common/IsVideoSuccess';
import { PrimaryButton } from 'components/common/button/PrimaryButton';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { subjectTeacherState } from 'recoil/subject/subjectTeacherState';
import { percentViewer } from 'util/subject/subjectUtil';

export function SubjectDetailVideoTeacher() {
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);
  const subjectTeacherData = useRecoilValue(subjectTeacherState);

  const [activeIndex, setActiveIndex] = useState(0);
  const inputRef = useRef(null);

  const [filterIndex, setFilterIndex] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const { data: subjectUserList, isLoading: subjectUserListIsLoading } = useQuery({
    queryKey: ['teacher--Video_Data', user, subjectTeacherData?.courseId, subjectTeacherData?.itemId],
    queryFn: async () =>
      postDataBodyQuery(
        `/teach/courses/${subjectTeacherData?.courseId}/modules/video/info?module_id=${subjectTeacherData?.moduleId}&item_id=${subjectTeacherData?.itemId}`,
        subjectTeacherData?.user_number_list
      ),
    enabled:
      !!subjectTeacherData.courseId &&
      !!subjectTeacherData.moduleId &&
      !!subjectTeacherData.assignment &&
      !!subjectTeacherData.studentLmsUserIds,
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const searchUserList = filterData?.filter(
      (el) => el.userName.includes(inputRef.current.value) || el.userLoginId.includes(inputRef.current.value)
    );

    if (activeIndex === 1 && inputRef.current.value === '') {
      const filterSearch = subjectUserList?.data?.filter((el) => el.status === '출석' || el.status === '지각');
      return setFilterData(filterSearch);
    }
    if (activeIndex === 1) {
      const filterSearch = subjectUserList?.data?.filter((el) => el.status === '출석' || el.status === '지각');
      const searchUserList = filterSearch?.filter(
        (el) => el.userName.includes(inputRef.current.value) || el.userLoginId.includes(inputRef.current.value)
      );
      return setFilterData(searchUserList);
    }

    if (activeIndex === 2 || inputRef.current.value === '') {
      const filterSearch = subjectUserList?.data?.filter((el) => el.status !== '출석' && el.status !== '지각');
      const searchUserList = filterSearch?.filter(
        (el) => el.userName.includes(inputRef.current.value) || el.userLoginId.includes(inputRef.current.value)
      );
      return setFilterData(searchUserList);
    }
    if (activeIndex === 2) {
      const filterSearch = subjectUserList?.data?.filter((el) => el.status !== '출석' && el.status !== '지각');
      return setFilterData(filterSearch);
    }

    if (inputRef.current.value === '') return setFilterData(subjectUserList?.data);

    setFilterData(searchUserList);
  };

  useEffect(() => {
    if (subjectUserList) return setFilterData(subjectUserList?.data);
  }, [subjectUserList]);

  const handleBtnClick = (index, type) => {
    setFilterIndex(index);

    if (type === 'all') {
      setActiveIndex(0);
      setFilterData(subjectUserList?.data);
    }

    if (type === 'completed') {
      const completedItems = subjectUserList?.data?.filter((el) => el.status === '출석' || el.status === '지각');
      setActiveIndex(1);
      setFilterData(completedItems);
    }

    if (type === 'uncompleted') {
      const uncompletedItems = subjectUserList?.data?.filter((el) => el.status !== '출석' && el.status !== '지각');
      setActiveIndex(2);
      setFilterData(uncompletedItems);
    }
  };

  return (
    <>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold fz-24'>{subjectTeacherData.title}</h3>

                  <PrimaryButton onClick={() => navigate('/subject', { replace: true })}>목록</PrimaryButton>
                </div>
              </div>

              {subjectUserListIsLoading ? (
                <SpinnerLoading />
              ) : (
                <>
                  {/* 셀렉트 information */}

                  {/* 아래 카드 */}
                  <div className='card p-20'>
                    <div className='mb-20 d-flex justify-content-between align-items-center'>
                      {/* //TODO 코드 리펙토링해야함 */}
                      <ul className='d-flex '>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(0, 'all');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 0 && 'btn-filter-active'}`}
                          >
                            전체 &#40;
                            {subjectUserListIsLoading && 0}
                            {subjectUserList?.data.length}
                            &#41;
                          </button>
                        </li>
                        <li className='me-3'>
                          <button
                            onClick={() => {
                              handleBtnClick(1, 'completed');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 1 && 'btn-filter-active'}`}
                          >
                            완료 &#40;
                            {subjectUserListIsLoading && 0}
                            {subjectUserList?.data.filter((el) => el.status === '출석' || el.status === '지각').length}
                            &#41;
                          </button>
                        </li>
                        <li className='me-3 '>
                          <button
                            onClick={() => {
                              handleBtnClick(2, 'uncompleted');
                            }}
                            className={`btn btn-style-none btn-filter-btn ${activeIndex === 2 && 'btn-filter-active'}`}
                          >
                            미완료 &#40;
                            {subjectUserListIsLoading && 0}
                            {subjectUserList?.data.filter((el) => el.status !== '출석' && el.status !== '지각').length}
                            &#41;
                          </button>
                        </li>
                      </ul>
                      <form onSubmit={handleOnSubmit}>
                        <input
                          type='text'
                          className='border rounded py-2 px-3'
                          ref={inputRef}
                          name='subjectTeacherName'
                          id='subjectTeacherName'
                          placeholder='이름 검색'
                        />
                      </form>
                    </div>

                    <Table>
                      <thead className='fw-bold border-bottom'>
                        <tr>
                          <th className='py-3' style={{ width: '7.07%' }}>
                            번호
                          </th>
                          <th className='py-3 text-start' style={{ width: '18.57%' }}>
                            학번
                          </th>
                          <th className='py-3 text-start' style={{ width: '32.22%' }}>
                            학생
                          </th>
                          <th className='py-3' style={{ width: '11.79%' }}>
                            상태
                          </th>
                          <th className='py-3 text-start' style={{ width: '18.57%' }}>
                            제출 완료 일자
                          </th>
                          <th className='py-3 text-start' style={{ width: '11.79%' }}>
                            현황
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterData.length === 0 && (
                          <tr>
                            <td colSpan={6}>
                              <EmptyComponent text={'검색 결과가 없습니다.'} transform={true} />
                            </td>
                          </tr>
                        )}
                        {filterData?.map((item, index) => {
                          return (
                            <tr className='border-bottom'>
                              <td className='py-3 text-center' style={{ width: '7.07%' }}>
                                {index + 1}
                              </td>
                              <td className='py-3' style={{ width: '18.57%' }}>
                                {item.userLoginId}
                              </td>
                              <td className='py-3' style={{ width: '32.22%' }}>
                                {item.userName}
                              </td>
                              <td className='py-3 text-center' style={{ width: '11.79%' }}>
                                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                  <IsVideoSuccess state={item.status} />
                                </div>
                              </td>
                              <td className='py-3' style={{ width: '18.57%' }}>
                                {item.completedAt ? moment(item.completedAt).format('YY-MM-DD HH:mm:ss') : '-'}
                              </td>
                              <td className='py-3' style={{ width: '11.79%' }}>
                                {/* <span className='mx-2'>{item.percent ? item.percent : '-'}</span>% */}
                                <span className='mx-2'>{percentViewer(item.percent)}</span>%
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
