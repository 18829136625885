import { reportPDFOptions } from 'pages/student/report/data';
import { useRef } from 'react';
import generatePDF, { usePDF } from 'react-to-pdf';

export const usePDFDownLoad = () => {
  const { targetRef } = usePDF({ filename: 'report.pdf' });
  const flexRef = useRef(null);
  const liberalRef = useRef(null);

  const chartOneRef = useRef(null);
  const chartTwoRef = useRef(null);
  const chartThreeRef = useRef(null);
  const chartFourRef = useRef(null);
  const chartFiveRef = useRef(null);

  const handleClickDownPDF = () => {
    const targetClass = targetRef.current.className;
    const flexClass = flexRef.current.className;
    const liberalClass = liberalRef.current.className;

    targetRef.current.className = 'react-pdf__Page__canvas';
    flexRef.current.className = 'd-flex';
    liberalRef.current.className = 'mt-4 justify-content-evenly d-flex gap-3';

    generatePDF(() => targetRef.current, reportPDFOptions);

    targetRef.current.className = targetClass;
    flexRef.current.className = flexClass;
    liberalRef.current.className = liberalClass;
  };

  const pdfDownButton = (className) => {
    return (
      <button
        onClick={() => handleClickDownPDF(className)}
        className='bg-primary rounded-3 text-white border-none py-3 px-4 btn-transition'
      >
        PDF 다운로드
        <i className='bi bi-filetype-pdf ms-2'></i>
      </button>
    );
  };

  const handleClickDownPDFAdmin = () => {
    const targetClass = targetRef.current.className;
    const flexClass = flexRef.current.className;
    const liberalClass = liberalRef.current.className;

    targetRef.current.className = 'react-pdf__Page__canvas';
    flexRef.current.className = 'd-block';
    liberalRef.current.className = 'mt-4 justify-content-evenly d-flex gap-3';

    generatePDF(() => targetRef.current, reportPDFOptions);

    targetRef.current.className = targetClass;
    flexRef.current.className = flexClass;
    liberalRef.current.className = liberalClass;
  };

  return {
    targetRef,
    flexRef,
    liberalRef,
    handleClickDownPDF,
    pdfDownButton,
    handleClickDownPDFAdmin,
    chartOneRef,
    chartTwoRef,
    chartThreeRef,
    chartFourRef,
    chartFiveRef,
  };
};
