import { CardHeader, SpinnerLoading } from 'components/common';
import { StreamEmpty } from 'components/common/dashboard';
import { Link } from 'react-router-dom';

/**
 *
 * @param {array} data 데이터 배열
 * @param {children} NodeElement 데이터 배열
 * @param {number} day 0,1,2 숫자값 0 오늘, 1 내일, 2 내일 모레
 * @returns
 */
export function ActivityItem({ data, isLoading, children, day }) {
  const dayNum = day === 0 ? 'Today' : day === 1 ? 'D-1' : day === 2 ? 'D-2' : '';
  const dayClass = day === 0 ? 'today-before' : day === 1 ? 'tomorrow-before' : day === 2 ? 'afterTomorrow-before' : '';

  return (
    <div className='col-lg-12'>
      <div className='card p-3'>
        <CardHeader title={dayNum}>
          <p>{children}</p>
        </CardHeader>
        {isLoading ? (
          <div className='py-5'>
            <SpinnerLoading />
          </div>
        ) : (
          <div className='today-list'>
            {data?.length !== 0 ? (
              <table className='table act-Table'>
                {data
                  ? data?.map((data) => {
                      return (
                        <tr>
                          <td className='actList py-0 my-0'>
                            <li className={`day-before ${dayClass}`}>
                              <div className='py-2'>
                                <Link to={''}>
                                  {data?.assignment_title}
                                  {data?.enrollment_title}
                                </Link>
                              </div>
                            </li>
                          </td>
                          <td className='actDate'>마감일 : {data?.until_date.slice(0, 10)}</td>
                        </tr>
                      );
                    })
                  : []}
              </table>
            ) : (
              <StreamEmpty />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
