import { postLogin } from 'api';
import { cookieOption, debounceSecond, delay, setSessionStorage } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import { jwtDecode } from 'jwt-decode';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { useUserState } from 'recoil/useUserState';
function Login() {
  // const autoFocusInput = useRef(null);
  const [cookies, setCookie] = useCookies(['token']);
  const setUser = useSetRecoilState(useUserState);
  const setLoginUser = useSetRecoilState(loginUser);
  const [verification, setVerification] = useState(false);
  const [userId, setUserId] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const debounceHandleSubmit = debounce(async (data) => {
    // const loginData = {
    //   userNumber: userId,
    //   password: userPassword,
    // };
    setIsLoading(true);
    try {
      const result = await postLogin(data);
      // 에러메세지를 반환하면 에러메세지 출력
      if (!result) return;
      if (result.errorCode && result.errorCode === 'U001') {
        setIsLoading(false);
        return setVerification(true);
      }
      await delay(3_000);

      const { accessToken, refreshToken } = result;
      setSessionStorage(accessToken);
      setCookie('refreshToken', refreshToken, { ...cookieOption });

      setIsLoading(false);
      setUser(jwtDecode(accessToken));
      setLoginUser(jwtDecode(accessToken));
      navigate('/');
    } catch (error) {
      console.error('error', error);
    }
  }, debounceSecond);
  const navigate = useNavigate();

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   debounceHandleSubmit();
  // };

  const resetLogin = () => {
    setUserId('');
    setUserPassword('');
  };

  useEffect(() => {
    // autoFocusInput.current.focus();
    resetLogin();
    // if (localStorage.getItem('subjectDetailData')) localStorage.removeItem('subjectDetailData');
    return () => {
      resetLogin();
      setVerification(null);
    };
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    debounceHandleSubmit(data);
  };

  return (
    <>
      <main id='loginPage'>
        <div id='login-wrapper'>
          {/* <div className="container"> */}
          {/* <section className="section register d-flex flex-column align-items-center justify-content-center py-4"> */}
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-5 col-md-6' id='login-container'>
                <div className='login-align'>
                  <div className='card' id='login-card'>
                    <div className='card-head'>
                      <div className='d-flex justify-content-center'>
                        <Link to='/' className='logo d-flex align-items-center w-auto'>
                          <img src='/assets/img/login_logo.png' alt='' id='login-img' />
                        </Link>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='pt-4 pb-2'>
                        <h5 className='card-title text-center pb-0 fs-4'>
                          <b className='color-primary'>KBU 성공전략</b> 로그인
                        </h5>
                      </div>

                      <form className='row g-3 needs-validation' onSubmit={handleSubmit(onSubmit)}>
                        <div className='col-12'>
                          <div className='d-flex align-items-center mb-2'>
                            <label htmlFor='yourUsername' className='form-label yourUsername m-0'>
                              학번 / 직번
                            </label>
                            <p className='fz-12 text-center danger-redText ms-3'>
                              {verification && '아이디와 비밀번호를 확인해주세요'}
                            </p>
                          </div>
                          <div className='input-group has-validation'>
                            <input
                              type='text'
                              name='username'
                              {...register('userNumber', { required: true })}
                              className='form-control'
                              id='yourUsername'
                              // value={userId}
                              // onChange={(e) => setUserId(e.target.value.trimStart())}
                              // required
                              // ref={autoFocusInput}
                              placeholder='학번 / 직원번호 또는 로그인 아이디를 입력하세요'
                            />
                            <div className='invalid-feedback'>학번 또는 직번을 입력해주세요</div>
                          </div>
                        </div>

                        <div className='col-12'>
                          <label htmlFor='yourPassword' className='form-label password'>
                            비밀번호
                          </label>
                          <input
                            type='password'
                            name='password'
                            className='form-control'
                            {...register('password', { required: true })}
                            id='yourPassword'
                            // value={userPassword}
                            // onChange={(e) => setUserPassword(e.target.value.trimStart())}
                            placeholder='비밀번호를 입력하세요'
                            // required
                          />
                          <div className='invalid-feedback'>비밀번호를 입력해주세요</div>
                        </div>

                        <div className='col-12'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='remember'
                              value='true'
                              id='rememberMe'
                            />
                            <label className='form-check-label' htmlFor='rememberMe'>
                              학번 / 사번 기억하기
                            </label>
                          </div>
                        </div>
                        <div className='col-12'>
                          <button className='btn w-100 login-btn' type='submit' onClick={onSubmit}>
                            로그인
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <>{isLoading && <SpinnerLoading />}</>
              </div>
              {/* </div> */}

              {/* </section> */}
              <div className='foot-section position-absolute bottom-0 end-0 py-3'>
                <div>
                  <span className='fw-light ms-3'>Copyright ⓒ 2024 KYUNGBOK UNIVERSITY. All Reserved.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Link to='/' className='back-to-top d-flex align-items-center justify-content-center'>
        <i className='bi bi-arrow-up-short'></i>
      </Link>
    </>
  );
}

export default Login;
