export const MyNeckItem = ({ title, subtitle, content, border }) => {
  const formattedContent = content?.replace(/\n/g, '<br />');
  return (
    <article className={`mb-4 mt-2 pb-3 ${border && 'border-bottom'}`}>
      <h4 className='fz-18 mx-20 color-table-gary fw-bold mb-3'>{title}</h4>
      <p className='fz-16 mx-20 color-liberal-description mb-3'>{subtitle}</p>
      <p
        className='fz-16 mx-20 text-gray1 bg-gray10 p-4 rounded lh-22 max-h-300 overflow-auto'
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      ></p>
    </article>
  );
};
