import { useNavigate } from 'react-router';

export const ReportBackButton = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <button className='border bg-58595B py-2 px-3 rounded text-white' onClick={handleGoBack}>
      목록
    </button>
  );
};
