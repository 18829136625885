import BreadCrumb from 'components/shared/BreadCrumb';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { adminSubjectState } from 'recoil/subject/adminSubjectState';
import { teacherSubjectState } from 'recoil/subject/teacherSubjectState';

export function SubjectTeacherLayout() {
  const resetTeacherSubjectState = useResetRecoilState(teacherSubjectState);
  const resetAdminSelectState = useResetRecoilState(adminSubjectState);
  useEffect(() => {
    return () => {
      resetTeacherSubjectState();
      resetAdminSelectState();
      localStorage.removeItem('teacherSubjectSelectData');
    };
  }, []);
  return (
    <>
      <main id='main' className={`main ${!'subjectId' && 'subject-icon-backdrop'}`}>
        <BreadCrumb
          home={'/subject'}
          sublink={'/subject'}
          title={'Online Learning Status'}
          subTitle={'온라인 학습현황'}
        />
        <Outlet />
      </main>
    </>
  );
}
