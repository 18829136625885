import moment from 'moment';

export const ReportFooter = () => {
  return (
    <div className='d-block justify-content-between d-sm-flex'>
      <div>{/* <img src='/assets/img/reportfooterimg.png' alt='경복대 로고' /> */}</div>
      <div className='color-text fw-bold fz-18 mt-4 mt-sm-0'>
        <span className='fz-18'>
          <i>위 내용과 같이 학기 성과 내용을 보고합니다.</i>
        </span>
        <p className='text-start text-sm-end mt-3 fz-18'>{moment().format('YYYY년 MM월 DD일')}</p>
      </div>
    </div>
  );
};
