import { useRecoilValue } from 'recoil';
import { adminColorState } from 'recoil/report/admin/adminColorState';
import { reportAdminChartsTitle } from '../data';
import { reportChartAdminColors, reportChartENLeftAxis, reportChartLeftAxis } from '../reportConstant';
import { ReportAdminChart } from './ReportAdminChart';

export const ReportAdminCharts = ({ en, ko, math, science, social }) => {
  const colors = useRecoilValue(adminColorState);
  const colorExtractor = (arr, subArr) => {
    try {
      const subArrMap = subArr?.map((el) => el.sub_division_name);
      const arra = arr?.filter((el) => {
        return subArrMap?.includes(el.sub_division_name);
      });

      const filterColor = arra.map((el) => el.color);

      return filterColor;
    } catch (error) {
      return [];
    }
  };

  //   { id: 1, title: '국어국문학과54', 국어국문학과54: '64' },
  if (!en && !ko && !math && !science && !social) return;
  const adminChartChanger = (arr) => {
    const filterArr = arr
      ?.map((item) => ({
        id: item.sub_division_name,
        title: item.sub_division_name,
        [item.sub_division_name]: item.sub_division_score,
      }))
      .toReversed();

    // const fillLength = Array(Math.max(en?.length, ko?.length, math?.length, science?.length, social?.length))
    //   .fill()
    //   .map((item, index) => ({
    //     id: '',
    //     title: `간호학과${index}`,
    //     간호학과: '0',
    //   }));
    // const fillArr = [...filterArr, ...fillLength].slice(0, fillLength?.length);
    // return fillArr;

    return filterArr;
  };

  const 평균구함_함수 = (arr) => {
    try {
      const 평균값 =
        arr?.reduce((acc, cur) => {
          return acc + cur.sub_division_score;
        }, 0) / arr.length;

      if (typeof 평균값 !== 'number') return 0;
      return 평균값;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const adminChartTitleChanger = (arr) => {
    const filterArr = arr?.map((item) => item.sub_division_name);
    return filterArr;
  };

  return (
    <>
      {/* en */}

      <div className='d-lg-flex d-block w-full justify-content-between gap-lg-3'>
        <div className='border admin-border-blue rounded-3 w-full'>
          <ReportAdminChart
            LeftAxis={reportChartLeftAxis}
            title={reportAdminChartsTitle.ko}
            data={adminChartChanger(ko)}
            // colors={colorExtractor(colors, ko)}
            colors={reportChartAdminColors.ko}
            keys={adminChartTitleChanger(ko)}
            average={평균구함_함수(ko)}
          />
        </div>
        <div className='border admin-border-blue rounded-3 w-full mt-4 mt-lg-0'>
          <ReportAdminChart
            maxValue={60}
            LeftAxis={reportChartENLeftAxis}
            title={`${reportAdminChartsTitle.en} - 60점 만점`}
            data={adminChartChanger(en)}
            // colors={colorExtractor(colors, en)}
            colors={reportChartAdminColors.en}
            keys={adminChartTitleChanger(en)}
            average={평균구함_함수(en)}
          />
        </div>
      </div>

      <div className='d-lg-flex d-block justify-content-between mt-4 gap-lg-3'>
        <div className='border admin-border-blue rounded-3 w-full'>
          <ReportAdminChart
            LeftAxis={reportChartLeftAxis}
            title={reportAdminChartsTitle.social}
            data={adminChartChanger(social)}
            // colors={colorExtractor(colors, social)}
            colors={reportChartAdminColors.social}
            keys={adminChartTitleChanger(social)}
            average={평균구함_함수(social)}
          />
        </div>
        <div className='w-full'>
          <div className='border admin-border-blue rounded-3 mt-4 mt-lg-0'>
            <ReportAdminChart
              LeftAxis={reportChartLeftAxis}
              title={reportAdminChartsTitle.science}
              data={adminChartChanger(science)}
              // colors={colorExtractor(colors, science)}
              colors={reportChartAdminColors.science}
              keys={adminChartTitleChanger(science)}
              average={평균구함_함수(science)}
              height={350}
            />
          </div>
          <div className='border admin-border-blue rounded-3 mt-4'>
            <ReportAdminChart
              LeftAxis={reportChartLeftAxis}
              title={reportAdminChartsTitle.math}
              data={adminChartChanger(math)}
              // colors={colorExtractor(colors, math)}
              colors={reportChartAdminColors.math}
              keys={adminChartTitleChanger(math)}
              average={평균구함_함수(math)}
              height={350}
            />
          </div>
        </div>
      </div>
    </>
  );
};
