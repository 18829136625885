export const ReportLiberalItem = ({ title, children, content, state }) => {
  const stateClasses = {
    Y: 'color-report-pass-blue bg-report-pass-blue',
    N: 'color-report-fail-blue bg-report-fail-blue',
  };

  const stateClass = stateClasses[state] || '';

  return (
    <div className='d-flex py-4 px-3 border rounded-4 mt-3 mt-lg-0 flex-grow-1 align-items-center'>
      <div
        className={`w4_625-h2_563rem d-flex justify-content-center align-items-center rounded-3 me-2 rounded-5 ${stateClass}`}
      >
        {content}
      </div>
      <div className='d-flex flex-column justify-content-between py-1 me-5'>
        <h4 className='fw-bold fz-18 color-liberal-title mb-2'>{title}</h4>
        <p className='fz-14 color-liberal-description white-nowrap'>{children}</p>
      </div>
    </div>
  );
};
