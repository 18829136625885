import { useEffect, useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { categoryType, setSessionStorage } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { useGetCommonApi } from 'hooks';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { viewSubjectTypeControl } from 'util/subject/subjectUtil';

export function SubjectTeacher() {
  const user = useRecoilValue(loginUser);
  const [filterData, setFilterData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const notStudentRole = user?.role !== 'STUDENT';
  const [subjectId, setSubjectId] = useState(
    sessionStorage.getItem('subjectData') ? JSON.parse(sessionStorage.getItem('subjectData')) : null
  );
  const [optionData, setOptionData] = useState([]);
  const {
    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,
  } = useGetRequiredApi();

  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();
  const handleSelectBox = (e) => {
    const findData = teacherSelectBoxQueryData.find((item) => item.course_id === e?.value);
    setSubjectId(findData);
    // 선택된 값이 있으면 로컬에 그값을 저장
    if (findData) return sessionStorage.setItem('subjectData', JSON.stringify(findData));
  };

  useEffect(() => {
    if (teacherSelectBoxQueryData) return setFilterData(teacherSelectBoxQueryData);
  }, [teacherSelectBoxQueryData]);

  const teacherSubjectApis = useQueries({
    queries: [
      {
        // 교수자 - [학습현황] 주차별 정보 API ( 셀렉트 박스 선택시 그것에 해당되는 모듈의 정보 api )
        queryKey: ['teacherSubjectListItem', user, subjectId?.course_id],
        queryFn: () =>
          getDataQuery(`/teach/courses/${subjectId?.course_id}/modules/info?total=${subjectId?.total_student_count}`),
        enabled: !!subjectId?.course_id && notStudentRole,
      },
      {
        // 교수자 - [학습현황] 과목의 모듈별 완료한 학생 수
        queryKey: ['teacherSubjectCompletedStudent', user, subjectId?.course_id],
        queryFn: () => getDataQuery(`/teach/courses/${subjectId?.course_id}/modules/completed-count`),
        enabled: !!subjectId?.course_id,
      },
      {
        // 교수자 - [학습현황] 과목을 수강중인 학생들의 아이디 목록
        queryKey: ['teacherSubjectStudentList', user, subjectId?.course_id],
        queryFn: () => getDataQuery(`/teach/courses/${subjectId?.course_id}/students`),
        enabled: !!subjectId?.course_id,
      },
      // 교수자 - [학습현황] 과목의 모듈별 완료한 학생 수 ( 토탈 수 구하는 api)
    ],
  });

  const [teacherSubjectList, teacherSubjectCompletedStudentList, teacherSubjectStudentList] = teacherSubjectApis?.map(
    (query) => query
  );
  const [teacherSubjectListData, teacherSubjectCompletedStudentListData, teacherSubjectStudentListData] =
    teacherSubjectApis?.map((query) => query?.data?.data);

  const { isLoading: teacherSubjectListIsLoading, isError: teacherSubjectListIsError } = teacherSubjectList;
  const { isLoading: teacherSubjectCompletedStudentListIsLoading, isError: teacherSubjectCompletedStudentListIsError } =
    teacherSubjectCompletedStudentList;
  const { isLoading: teacherSubjectStudentListIsLoading, isError: teacherSubjectStudentListIsError } =
    teacherSubjectStudentList;

  const handleSetSubjectDetail = (item) => {
    try {
      const subjectDetailData = {
        courseId: subjectId.course_id,
        moduleId: item.module_id,
        assignment: item.content_id,
        studentLmsUserIds: teacherSubjectStudentListData?.student_id_list,
        course_title: subjectId.course_name,
        title: item.title,
      };
      setSessionStorage(JSON.stringify(subjectDetailData), 'subjectDetailData');
      // localStorage.setItem('subjectDetailData', JSON.stringify(subjectDetailData));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return setOptionData(
      teacherSelectBoxQueryData?.map((item) => ({
        value: item.course_id,
        label: item.course_name,
      }))
    );
  }, [teacherSelectBoxQueryData]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        teacherSelectBoxQueryData?.map((item) => ({
          value: item.course_id,
          label: item.course_name,
        }))
      );
    });

  return (
    <>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold'>
                    <i className='bi bi-bank2 me-2'></i>
                    {/* 학기 정보 */}
                    {semesterData ? semesterData?.data?.name : '기본학기'}
                  </h3>

                  <div className='d-flex align-items-center w-100 ps-3'>
                    <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                      과목
                    </label>

                    <AsyncSelect
                      className='w-100'
                      defaultInputValue='과목 선택'
                      placeholder='과목 선택'
                      isLoading={teacherSelectBoxQueryIsLoading}
                      cacheOptions
                      defaultOptions={optionData}
                      onChange={handleSelectBox}
                      loadOptions={promiseOptions}
                    />
                  </div>
                </div>
              </div>

              {teacherSubjectListIsLoading && <SpinnerLoading />}
              {subjectId && (
                <>
                  {/* 셀렉트 information */}
                  <div className=''>
                    {subjectId && (
                      <section className='card px-3 py-4 d-flex justify-content-between align-items-center flex-row'>
                        <div className='d-flex fw-bold fz-18'>
                          <h3 className='d-flex --Palette-DarkGray-80'>{subjectId?.course_name}</h3>
                        </div>
                        <div className='d-flex fz-14 noto-sans align-items-center'>
                          <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary CHA_LIGHT'>
                            <span className=''>교과코드:</span>
                            <span className='fw-bold'>{subjectId?.course_id}</span>
                          </p>
                        </div>
                      </section>
                    )}

                    {/* 아래 카드 */}
                    <div>
                      <div className='mb-3 d-flex px-3'>
                        <i className='bi bi-people-fill me-3'></i>
                        <p className='fz-16'>총 학생 수: {subjectId?.total_student_count}</p>
                      </div>

                      {/* 주차 아이템 */}
                      {teacherSubjectListData?.module_list?.map((item, index) => (
                        <div key={item.module_id} className='rounded-2 overflow-hidden bg-white mb-5'>
                          {/* header */}
                          <div className='p-20 bg-second fw-bold text-white d-flex justify-content-between align-items-center'>
                            <p>
                              <span className='me-1'>{index + 1}</span>주차
                            </p>
                            <p className='fw-bold'>
                              <i className='bi bi-check-circle-fill --cha_yellow me-2'></i>
                              주차 완료 :
                              {teacherSubjectCompletedStudentListIsLoading
                                ? // TODO 로딩 스피너 나중에 추가
                                  '0'
                                : teacherSubjectCompletedStudentListData[index]?.completed_student_count}
                            </p>
                          </div>
                          {/* body */}
                          <div className='p-20 pb-2 fz-14'>
                            <Table className=''>
                              <thead className='fw-bold --Palette-DarkGray-80'>
                                <tr className='border-bottom'>
                                  <th className='text-start p-1 py-3' style={{ width: '17.78%', minWidth: '70px' }}>
                                    카테고리
                                  </th>
                                  <th className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                    제목
                                  </th>

                                  <th className='text-start p-1 py-3' style={{ width: '11.00%' }}></th>
                                </tr>
                              </thead>
                              <tbody className='--Palette-DarkGray-80'>
                                {item && item?.module_item_list.length !== 0 ? (
                                  item?.module_item_list?.map((el) => (
                                    <tr key={el.item_id} className='border-bottom'>
                                      <td className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                        {categoryType(el.type)}
                                      </td>
                                      <td className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                        {el.title}
                                      </td>

                                      <td className='text-end p-1 py-3' style={{ width: '11.00%' }}>
                                        <Link
                                          onMouseEnter={() => {
                                            handleSetSubjectDetail(el);
                                          }}
                                          onClick={() => {
                                            handleSetSubjectDetail(el);
                                          }}
                                          to={`/subject/detail`}
                                        >
                                          {viewSubjectTypeControl(el.type) || (
                                            <span className='badge link-badge border fz-14'>자세히 보기</span>
                                          )}
                                        </Link>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      <div className='d-flex justify-content-center'>
                                        <EmptyComponent text='등록된 강의가 없습니다' transform={true} />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
