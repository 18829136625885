import { arrChunk } from 'assets/constant';
import EmptyComponent from 'components/common/EmptyComponent';
import { PaginationCustom } from 'components/common/pagination';
import { useReport } from 'hooks/report/useReport';
import { useReportQuery } from 'hooks/report/useReportQuery';
import { ReportSelectLayout } from 'pages/student/report/common/ReportSelectLayout';
import { reportPDFOptions } from 'pages/student/report/data';
import { ReportStudentTableItem } from 'pages/student/report/teacher/ReportStudentTableItem';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import generatePDF, { usePDF } from 'react-to-pdf';
import { useRecoilState } from 'recoil';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { studentPagination } from 'recoil/report/studentPagination';
import { teacherReportState } from 'recoil/report/teacher/teacherReportState';
import { teacherStudentState } from 'recoil/report/teacher/teacherStudentState';

export const ReportStudentList = () => {
  const navigate = useNavigate();
  const { semesterSelector, departmentSelector, reportSearchForm, searchInputRef } = useReport();
  const { targetRef } = usePDF({ filename: 'report.pdf' });
  const handleClickDownPDF = () => {
    generatePDF(() => targetRef.current, reportPDFOptions);
  };
  const [studentState, setStudentState] = useRecoilState(teacherStudentState);
  const [reportState, setReportState] = useRecoilState(studentReportState);
  const [reacherReportState, setReacherReportState] = useRecoilState(teacherReportState);
  const [filterData, setFilterData] = useState([]);
  const { 선생_자신의학생_조회_정보, 선생_자신의학생_조회_로딩 } = useReportQuery();

  const [currentPage, setCurrentPage] = useRecoilState(studentPagination);
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const searchUser = 선생_자신의학생_조회_정보?.data.filter(
      (item) =>
        item.student_number.includes(searchInputRef.current.value) ||
        item.student_name.includes(searchInputRef.current.value)
    );
    setFilterData(searchUser);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (선생_자신의학생_조회_정보?.data) {
      setFilterData(선생_자신의학생_조회_정보?.data);
    }
  }, [선생_자신의학생_조회_정보]);

  return (
    <section>
      <div className='row'>
        <div className='col'>
          <div className='position-relative'>
            {/* 셀렉트 박스 */}
            <ReportSelectLayout>
              <div className='d-flex align-items-center' style={{ width: '40%' }}>
                {semesterSelector(true, setReportState, reportState)}
              </div>
              <div className='d-flex align-items-center me-3' style={{ width: '30%' }}>
                {departmentSelector(true, setReacherReportState)}
              </div>

              <div className='d-flex align-items-center flex-grow-1'>
                {reportSearchForm(handleOnSubmit, !reacherReportState)}
              </div>
            </ReportSelectLayout>

            {선생_자신의학생_조회_로딩?.data && (
              <div className='card p-3 w-100' style={{ height: '100px' }}>
                <SpinnerLoading />
              </div>
            )}

            {reacherReportState ? (
              <section>
                <h3 className='fw-bold fz-20 lh-24 mb-4'>학생 성과보고서 현황</h3>
                <div className='card p-3'>
                  <Table>
                    <thead>
                      <tr className='fz-14'>
                        <th className='py-3 fz-14'>번호</th>
                        <th className='py-3 text-start'>학번/사번 &#40;로그인ID&#41;</th>
                        <th className='py-3 text-start'>이름</th>
                        <th className='py-3 text-start' colSpan={2}>
                          학과/과목
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {선생_자신의학생_조회_정보?.data?.map(
                        ({ student_id, student_number, student_name, sub_division }, index) => (
                          <ReportStudentTableItem
                            key={student_id}
                            index={index}
                            classNumber={student_number}
                            name={student_name}
                            department={sub_division}
                            onClick={() => {
                              setStudentState({ term_id: reportState?.value, student_id });
                              navigate(`${student_id}`);
                            }}
                          />
                        )
                      )} */}
                      {!arrChunk(filterData, 10)[currentPage - 1] ? (
                        <tr>
                          <td colSpan={10}>
                            <EmptyComponent text='검색 결과가 없습니다.' transform></EmptyComponent>
                          </td>
                        </tr>
                      ) : (
                        arrChunk(filterData, 10)[currentPage - 1]?.map(
                          ({ student_id, student_number, student_name, sub_division }, index) => (
                            <ReportStudentTableItem
                              key={student_id}
                              index={
                                currentPage > 0 ? (currentPage - 1) * 10 + (index + 1) : currentPage - 1 + (index + 1)
                              }
                              classNumber={student_number}
                              name={student_name}
                              department={sub_division}
                              onClick={() => {
                                setStudentState({ term_id: reportState?.value, student_id });
                                navigate(`${student_id}`, {
                                  state: { student_name, student_number, sub_division, student_id },
                                });
                              }}
                            />
                          )
                        )
                      )}
                    </tbody>
                  </Table>
                  <div className='d-flex align-items-center justify-content-center position-relative'>
                    <p className='float-left position-absolute top-left-50 fz-14'>
                      전체 게시물수 : {filterData.length} 전체 페이지 : {currentPage}&nbsp;/&nbsp;
                      {arrChunk(filterData, 10).length}
                      {/* {Math.ceil(noticeList.length / 5)} */}
                    </p>
                    <PaginationCustom
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      // data={adminAllUserData?.content}
                      importantDataLength={filterData?.length}
                      itemsCountPerPage={10}
                    />
                  </div>
                </div>
              </section>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
