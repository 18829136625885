import { ResponsiveBar } from '@nivo/bar';
import { reportChartKeys, reportChartMargin } from '../reportConstant';

export const ReportStudentChart = ({ chartTitle = '차트 제목', data, colors, maxValue = 100, LeftAxis }) => {
  return (
    <div className='w-full'>
      <h3 className='text-center mt-3 mb-4 fw-bold fz-14'>{chartTitle}</h3>
      <div className='h-300'>
        <ResponsiveBar
          data={data}
          // 데이터에 사용될 키
          keys={reportChartKeys}
          // 인덱싱 하는 데 사용되는 키
          indexBy='title'
          // 차트의 여백
          margin={reportChartMargin}
          // 차트아이템의 패딩
          padding={0.25}
          // 최대값
          maxValue={maxValue}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: false }}
          colors={colors}
          borderRadius={8}
          axisBottom={{
            tickSize: 4,
            tickPadding: 8,
            legendPosition: 'middle',
            legendOffset: -60,
          }}
          axisLeft={{
            tickValues: LeftAxis,
            tickSize: 4,
            tickPadding: 8,
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          enableGridY={false}
          enableLabel={false}
          totalsOffset={0}
          // 평균값 가로선 값
          // markers={[{ axis: 'y', value: 50, lineStyle: { stroke: 'black', strokeWidth: 2, strokeDasharray: '6, 6' } }]}
        />
      </div>
    </div>
  );
};
