import { atom } from 'recoil';

export const adminSubjectState = atom({
  key: 'adminSubjectState',
  default: {
    lectureId: null,
    subjectId: null,
    total_student_count: null,
    title: null,
    lectureTitle: null,
    subjectSubjectTitle: null,
  },
});
