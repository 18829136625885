export const LecturePassState = ({ content, state }) => {
  const stateClasses = {
    PASS: 'color-report-pass-blue bg-report-pass-blue',
    FAIL: 'color-report-fail-blue bg-report-fail-blue',
  };
  const stateClass = stateClasses[state] || '';
  return (
    <div className={`w3_563-h-1_875rem rounded-5 d-flex justify-content-center align-items-center fz-12 ${stateClass}`}>
      {content}
    </div>
  );
};
