import { LecturePassState } from '../LecturePassState';

export const ReportStudentScoreItem = ({
  title,
  state,
  content,
  my_score,
  average_score,
  department_average_score,
}) => {
  return (
    <tr className='fz-14'>
      <th className='text-start' style={{ width: '25%' }}>
        <p className='py-3 fz-14'>{title}</p>
      </th>
      <td className='text-center'>
        <div className='d-flex justify-content-center align-items-center py-2'>
          <LecturePassState state={state} content={content} />
        </div>
      </td>
      <td className='text-center bg-report-myScore-pink'>
        <p className='w-full h-full d-flex justify-content-center align-items-center py-3'>{my_score}</p>
      </td>
      <td className='text-center'>
        <p className='w-full h-full d-flex justify-content-center align-items-center py-3'>{average_score}</p>
      </td>
      <td className='text-center'>
        <p className='w-full h-full d-flex justify-content-center align-items-center py-3'>
          {department_average_score}
        </p>
      </td>
    </tr>
  );
};
