import { CardLayout } from 'components/shared/card/CardLayout';
import { CardSectionTitle } from 'components/shared/card/CardSectionTitle';
import { ReportLectureItem } from '../ReportLectureItem';

export const ReportAdminCard = ({ data }) => {
  return (
    <CardLayout title='1. 세부 학습목표 성취율'>
      {/* 프로그레스바 섹션 */}
      <section>
        <CardSectionTitle index='1' title='성공적인 대학생활' />
        <table className='w-full'>
          <thead>
            <tr>
              <th className='text-start ps-0' colSpan={2}>
                세부학습목표
              </th>
              <th className='text-start'>성취율</th>
              <th className='text-center'>상태</th>
            </tr>
          </thead>
          <tbody>
            <ReportLectureItem
              title='교육목표 이해도'
              percent={data?.first_one}
              content='학생은 경복대학교의 설립이념과 교육목표 이해한다.'
              type={data?.first_one_status}
            />
            <ReportLectureItem
              title='대학 자원 활용'
              percent={data?.first_two}
              content='학생은 성공적인 대학생활을 위하여 대학의 자원을 적절하게 활용한다.'
              type={data?.first_two_status}
            />
            <ReportLectureItem
              title='진단검사 실시'
              percent={data?.first_three}
              content='학생은 개인 맞춤형 성공지원을 위한 진단검사에 참여한다.'
              type={data?.first_three_status}
            />
            <ReportLectureItem
              title='협력적인 관계형성'
              percent={data?.first_four}
              content='학생은 지도교수 및 대학 구성원과 협력적인 관계를 형성한다.'
              type={data?.first_four_status}
            />
            <ReportLectureItem
              title='행사참여'
              percent={data?.first_five}
              content='학생은 진로설계를 위한 추천하는 활동 및 행사에 참여 한다.'
              type={data?.first_five_status}
            />
            <ReportLectureItem
              title='트랙설계'
              percent={data?.first_six}
              content='학생은 진로설계에 따른 자신의 교육 트랙을 설계 한다.'
              type={data?.first_six_status}
            />
          </tbody>
        </table>
      </section>

      <section>
        <CardSectionTitle index='2' title='미래역량 향상' />
        <table className='w-full'>
          <thead>
            <tr>
              <th className='text-start ps-0' colSpan={2}>
                세부학습목표
              </th>
              <th className='text-start'>성취율</th>
              <th className='text-center'>상태</th>
            </tr>
          </thead>
          <tbody>
            <ReportLectureItem
              title='기초소양함양'
              percent={data?.second_one}
              content='학생은 사회인으로서 요구되는 기초소양과 의사소통능력을 함양한다.'
              type={data?.second_one_status}
            />
            <ReportLectureItem
              title='필수교육이수'
              percent={data?.second_two}
              content='학생은 사회인으로서 필요한 필수교육을 이수한다.'
              type={data?.second_two_status}
            />
            <ReportLectureItem
              title='AI 활용 역량'
              percent={data?.second_three}
              content='학생은 미래사회 기반기술인 AI를 이해하고 활용 할 수 있는 능력을 갖춘다.'
              type={data?.second_three_status}
            />
          </tbody>
        </table>
      </section>
    </CardLayout>
  );
};
