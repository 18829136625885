import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { adminReportState } from 'recoil/report/admin/adminReportState';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { teacherReportState } from 'recoil/report/teacher/teacherReportState';

export const useAdminReportQuery = () => {
  const user = useRecoilValue(loginUser);
  const reportState = useRecoilValue(studentReportState);
  const reacherReportState = useRecoilValue(teacherReportState);
  const adminReport = useRecoilValue(adminReportState);

  const { data: 관리자_전체학과_선택_정보, isLoading: 관리자_전체학과_선택_로딩 } = useQuery({
    queryKey: ['관리자_전체학과_선택_정보', user, reportState, adminReport],
    queryFn: () => getDataQuery(`/statics/first/freshman?term_id=${reportState?.value}`),
    enabled: user?.role === 'ADMIN' && !!reportState && !adminReport?.value,
  });

  const { data: 관리자_개별학과선택_정보, isLoading: 관리자_개별학과선택_로딩 } = useQuery({
    queryKey: ['관리자_개별학과선택_정보', user, reportState?.value, adminReport?.value],
    queryFn: () =>
      getDataQuery(`/statics/first/sub-division?term_id=${reportState?.value}&sub_division_id=${adminReport?.value}`),
    enabled: user?.role === 'ADMIN' && !!reportState?.value && !!adminReport?.value,
  });

  const { data: 관리자_전체교양_정보, isLoading: 관리자_전체교양_로딩 } = useQuery({
    queryKey: ['관리자_교양_정보', user, reportState, adminReport],
    queryFn: () => getDataQuery(`/statics/seconde-two/freshman?term_id=${reportState?.value}`),
    enabled: user?.role === 'ADMIN' && !adminReport?.value && !!reportState,
  });

  const { data: 관리자_개별교양_정보, isLoading: 관리자_개별교양_로딩 } = useQuery({
    queryKey: ['관리자_개별교양_정보1', user, reportState, adminReport],
    queryFn: () =>
      getDataQuery(
        `/statics/second-two/sub-division?term_id=${reportState?.value}&sub_division_id=${adminReport?.value}`
      ),
    enabled: user?.role === 'ADMIN' && !!adminReport?.value && !!reportState,
  });

  const { data: 관리자_전체과목차트_정보, isLoading: 관리자_전체과목차트_로딩 } = useQuery({
    queryKey: ['관리자_전체과목차트_정보!', user, reportState, adminReport],
    queryFn: () => getDataQuery(`/statics/admin/score?term_id=${reportState?.value}`),
    enabled: user?.role === 'ADMIN' && !!reportState?.value,
  });

  const { data: 관리자_개별과목차트_정보, isLoading: 관리자_개별과목차트_로딩 } = useQuery({
    queryKey: ['관리자_개별과목차트_정보!', user, reportState, adminReport],
    queryFn: () =>
      getDataQuery(`/statics/professor/score?term_id=${reportState?.value}&subdivision_name=${adminReport?.label}`),
    enabled: user?.role === 'ADMIN' && !!reportState?.value && !!adminReport?.value,
  });

  const { data: 관리자_학생목록_정보, isLoading: 관리자_학생목록_로딩 } = useQuery({
    queryKey: ['관리자_학생목록!', user, reportState],
    queryFn: () => getDataQuery(`/statics/students?term_id=${reportState?.value}`),
    enabled: user?.role === 'ADMIN' && !!reportState?.value,
  });

  const { data: 어드민_두번째_차트_정보, isLoading: 어드민_두번째_차트_로딩 } = useQuery({
    queryKey: ['어드민_두번째_차트_정보', user, user, reportState?.value, adminReport?.label],
    queryFn: () =>
      getDataQuery(`/statics/professor/score?term_id=${reportState?.value}&subdivision_name=${adminReport?.label}`),
    enabled: user?.role !== 'STUDENT' && !!reportState?.value && !!adminReport?.label,
  });

  return {
    관리자_전체학과_선택_정보,
    관리자_전체학과_선택_로딩,
    관리자_개별학과선택_정보,
    관리자_개별학과선택_로딩,

    관리자_전체교양_정보,
    관리자_전체교양_로딩,
    관리자_개별교양_정보,
    관리자_개별교양_로딩,

    관리자_전체과목차트_정보,
    관리자_전체과목차트_로딩,
    관리자_개별과목차트_정보,
    관리자_개별과목차트_로딩,

    관리자_학생목록_정보,
    관리자_학생목록_로딩,
    어드민_두번째_차트_정보,
  };
};
