import { JANDI_COMPLETED, JANDI_LOCKED, JANDI_STARTED, JANDI_UNLOCKED } from 'assets/constant';
import styles from './JandiList.module.css';

/**
 * JandiList 컴포넌트는 주어진 type에 따라 다른 스타일을 가지는 리스트 아이템을 렌더링합니다.
 *
 * @component
 * @param {Object} props - JandiList 컴포넌트의 속성들
 * @param {string} props.type - 리스트 아이템의 상태를 나타내는 문자열 (참여, 미참여, 진행중)
 * @param {ReactNode} props.children - 리스트 아이템의 내용을 포함하는 React 요소
 * @returns {JSX.Element} JandiList 컴포넌트의 인스턴스
 */
function JandiList({ children, type }) {
  const stateClasses = {
    [JANDI_COMPLETED]: styles.jandiDone,
    [JANDI_STARTED]: styles.jandiProgress,
    [JANDI_UNLOCKED]: styles.jandiNotDone,
    [JANDI_LOCKED]: styles.jandiNotDone,
  };

  const stateClass = stateClasses[type] || '';

  return <li className={`${styles.jandiItem} ${stateClass}`}>{children}</li>;
}

export default JandiList;
