import { ProgressBar } from 'react-bootstrap';

export const ReportTeacherScoreItem = ({ title, percent = 0, average_score, average_grade_score, submit, total }) => {
  return (
    <tr className='fz-14'>
      <th className='text-start'>
        <p className='py-3 fz-14'>{title}</p>
      </th>
      <td className='text-center h-full align-center'>
        <div className='d-flex justify-content-center align-items-center py-3 h-full align-middle px-3'>
          <ProgressBar variant='info' now={percent} animated className='w-full me-2 progressbar-filter' />
          <span className='ms-2 white-nowrap'>
            {percent}&nbsp;%&#40;{submit}/{total}&#41;
          </span>
        </div>
      </td>
      <td className='text-center bg-table-teacher-gray'>
        <p className='w-full h-full d-flex justify-content-center align-items-center py-3'>{average_score}</p>
      </td>
      <td className='text-center'>
        <p className='w-full h-full d-flex justify-content-center align-items-center py-3'>{average_grade_score}</p>
      </td>
    </tr>
  );
};
