import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { findUserRoleImg } from 'assets/constant';
import { AdminUserProfilePopup } from 'components/common/adminuserprofilepopup/AdminUserProfilePopup';
import BreadCrumb from 'components/shared/BreadCrumb';
import { useAdminPatchApi } from 'hooks/admin/patchapi/useAdminPatchApi';
import { useAdminUserSetting } from 'hooks/admin/useAdminUserSetting';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form, Tab, Tabs, Toast } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { adminUserPopupState } from 'recoil/adminpopup/adminUserSettingPopup';
import { userNameSplit } from 'util/admin/adminAccountTable';

export default function AdminSetting() {
  const [key, setKey] = useState('profile');
  const [popupState, setPopupState] = useRecoilState(adminUserPopupState);
  const path = window.location.pathname.split('/')[3];

  const {
    // 어드민이 유저의 정보 조회 api
    adminUserProfileData,
    adminUserProfileIsLoading,
    adminUserProfileIsError,
  } = useAdminUserSetting(path);
  const { patchUserPasswordMutation } = useAdminPatchApi(path);

  const { data: userSetting } = useQuery({
    queryKey: ['AdminUserSetting', path],
    queryFn: () => getDataQuery(`/users/setting?id=${adminUserProfileData?.userNumber}`),
    enabled: key === 'setting',
  });
  const handleClickPopup = () => {
    setPopupState({
      ...popupState,
      popupState: true,
    });
  };

  useEffect(() => {
    return () => {
      setPopupState({
        popupState: false,
        toastState: false,
      });
    };
  }, []);

  return (
    <>
      <main id='main' className='main'>
        <BreadCrumb home={'/useraccounts'} sublink={'/useraccounts'} title={'User Accounts'} subTitle={'계정관리'}>
          &nbsp; / 사용자 계정관리 / {userNameSplit(adminUserProfileData?.name)}
        </BreadCrumb>
        <div className='row'>
          {/* 이미지 카드 */}
          <div className='col-4'>
            <div className='card p-4 text-center'>
              <div>
                <img src={findUserRoleImg(adminUserProfileData?.role)} alt='유저 프로필사진' />
              </div>
              <div className='gap-3 mt-3'>
                <h3 className='h1'>{userNameSplit(adminUserProfileData?.name)}</h3>
                <p className='mt-2'>학번 /사번 : {adminUserProfileData?.userNumber}</p>
                <p className='mt-2'>
                  {adminUserProfileData?.divisionName} {adminUserProfileData?.subDivisionName}
                </p>
              </div>
            </div>
          </div>

          {/* 탭 */}
          <div className='col-8'>
            <div className='card p-4'>
              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                defaultActiveKey='home'
                transition={false}
                id='noanim-tab-example'
                className='mb-3'
              >
                <Tab eventKey='profile' title='프로필'>
                  <div>
                    <h4 className='fw-bold h2'>사용자 상세정보</h4>

                    <ul className='py-3'>
                      <li className='row py-2'>
                        <p className='col-2'>이름</p>
                        <p className='col'>{userNameSplit(adminUserProfileData?.name)}</p>
                      </li>
                      <li className='row py-2'>
                        {/* //TODO jwt토큰에 생년월일, 연락처 정보가 없음 나중에 수정되면 추후 수정 */}
                        <p className='col-2'>생년월일</p>
                        <p className='col'>{adminUserProfileData?.birth}</p>
                      </li>
                      <li className='row py-2'>
                        <p className='col-2'>연락처</p>
                        <p className='col'>{adminUserProfileData?.mobile}</p>
                      </li>
                      <li className='row py-2'>
                        <p className='col-2'>이메일</p>
                        <p className='col'>{adminUserProfileData?.email}</p>
                      </li>
                      <li className='row py-2'>
                        <p className='col-2'>학번/사번</p>
                        <p className='col'>{adminUserProfileData?.userNumber}</p>
                      </li>
                      <li className='row py-2'>
                        <p className='col-2'>학부/학과</p>
                        <p className='col'>
                          {adminUserProfileData?.divisionName} {adminUserProfileData?.subDivisionName}
                        </p>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey='setting' title='설정'>
                  <ul className='py-3'>
                    <li className='row mb-3'>
                      <p className='col-2'>최근접속일시</p>
                      <p className='col'>
                        {/*
                         */}
                        {userSetting
                          ? userSetting?.data?.errorCode
                            ? '-'
                            : moment(userSetting?.recentLoginAt).format('YYYY.MM.DD HH:mm:ss')
                          : '-'}
                        {/*
                         */}
                      </p>
                    </li>
                    <li className='row py-3'>
                      <p className='col-2'>알림 설정</p>
                      <div className='col'>
                        <Form className='d-flex flex-column align-items-start'>
                          <Form.Check disabled reverse type='switch' id='custom-switch1' label='사이트 알림' />
                          <Form.Check
                            disabled
                            reverse
                            className='mt-3'
                            type='switch'
                            id='custom-switch2'
                            label='이메일 알림'
                          />
                        </Form>
                      </div>
                    </li>
                  </ul>
                </Tab>
                <Tab eventKey='password' title='비밀번호 초기화'>
                  <section className='p-3'>
                    <h3 className='fz-18 fw-bold mb-3'>비밀번호 초기화</h3>

                    <div className='mb-4'>
                      <p>계정 비밀번호를 초기 비밀번호로 초기화 합니다.</p>
                      <p>사용자는 초기 비밀번호로 접속 후 비밀번호를 변경 할 수 있습니다. </p>
                    </div>
                    <button onClick={handleClickPopup} className='fz-16 badge link-badge border'>
                      초기화
                    </button>
                  </section>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </main>

      {/* <AdminUserProfilePopup title='비밀번호 초기화' iconType='danger'>
        <p style={{ color: '#637381' }}>너 초기화 할꺼야?</p>
      </AdminUserProfilePopup> */}

      {popupState.popupState && (
        <AdminUserProfilePopup title='비밀번호 초기화' iconType='danger' successMutation={patchUserPasswordMutation}>
          <p style={{ color: '#637381' }}>선택한 계정의 비밀번호를 정말 초기화하시겠습니까?</p>
        </AdminUserProfilePopup>
      )}
      <>
        <Toast
          className='position-fixed toast'
          onClose={() => setPopupState({ ...popupState, toastState: false })}
          show={popupState.toastState}
          delay={3000}
          position={'top-end'}
          autohide
        >
          <Toast.Body className='d-flex align-items-center'>
            <div
              className='p-2 d-flex justify-content-center align-items-center rounded-5 me-2'
              style={{ background: '#22AD5C' }}
            >
              <i className='bi bi-check-circle text-white'></i>
            </div>
            비밀번호가 초기화되었습니다
          </Toast.Body>
        </Toast>
      </>
    </>
  );
}
