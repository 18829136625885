import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

export const useGetRequiredApi = (adminSelectState) => {
  const user = useRecoilValue(loginUser);

  const checkStudent = user?.role === 'STUDENT';
  const checkTeacher = user?.role === 'TEACHER';
  const checkAdmin = user?.role === 'ADMIN';

  const requiredApis = useQueries({
    queries: [
      {
        //   온라인 학습현황 과목선택 셀렉트박스 쿼리
        queryKey: ['subjectStudent', user],
        queryFn: () => getDataQuery(`/subject/users/me/enrollment/all`),
        enabled: checkStudent,
      },
      {
        // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
        // subject 상위 select-box list
        queryKey: ['teacherSubjectTitle', user],
        queryFn: () => getDataQuery('/teach/me/courses'),
        enabled: checkTeacher,
      },
      {
        // 관리자 - 전체 학과 리스트 조회
        queryKey: ['adminSubjectTitle', user],
        queryFn: () => getDataQuery('/teach/courses/sub-accounts'),
        enabled: checkAdmin,
      },
      {
        // 관리자 - 해당 학과의 과목 리스트
        queryKey: ['adminSubjectSubTitle', user, adminSelectState],
        queryFn: () => getDataQuery(`/teach/courses/account/${adminSelectState}/courses`),
        enabled: checkAdmin && !!adminSelectState,
      },
    ],
  });

  const [studentSelectBoxQuery, teacherSelectBoxQuery, adminSelectBoxQuery, adminSubSelectBoxQuery] = requiredApis.map(
    (query) => query
  );
  const [studentSelectBoxQueryData, teacherSelectBoxQueryData, adminSelectBoxQueryData, adminSubSelectBoxQueryData] =
    requiredApis.map((query) => query?.data?.data);

  const { isLoading: studentSelectBoxQueryIsLoading, isError: studentSelectBoxQueryIsError } = studentSelectBoxQuery;
  const { isLoading: teacherSelectBoxQueryIsLoading, isError: teacherSelectBoxQueryIsError } = teacherSelectBoxQuery;
  const { isLoading: adminSelectBoxQueryIsLoading, isError: adminSelectBoxQueryIsError } = adminSelectBoxQuery;
  const { isLoading: adminSubSelectBoxQueryIsLoading, isError: adminSubSelectBoxQueryIsError } = adminSubSelectBoxQuery;

  return {
    //   온라인 학습현황 과목선택 셀렉트박스 쿼리
    studentSelectBoxQueryData,
    studentSelectBoxQueryIsLoading,
    studentSelectBoxQueryIsError,

    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,

    // 관리자 - 전체 학과 리스트 조회
    adminSelectBoxQueryData,
    adminSelectBoxQueryIsLoading,
    adminSelectBoxQueryIsError,

    // 관리자 - 해당 학과의 과목 리스트
    adminSubSelectBoxQueryData,
    adminSubSelectBoxQueryIsLoading,
    adminSubSelectBoxQueryIsError,
  };
};
